import { ItemTypeItemType as LineItemType } from '@wix/ambassador-ecom-v1-checkout/types';
import { CouponStatusStatus as ReferralCouponStatus } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { LoyaltyCoupon, Status as LoyaltyCouponStatus } from '@wix/ambassador-loyalty-v1-coupon/types';
import { LoyaltyEarningRule, Status as EarningRuleStatus } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward as LoyaltyReward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { loadLoyaltyCouponNames } from '@wix/loyalty-coupon-names';
import { IUser } from '@wix/yoshi-flow-editor';
import { autorun, computed, IComputedValue, reaction, runInAction, when } from 'mobx';

import { Experiment } from '../../constants';
import { createCurrencyFormatter, createNumberFormatter } from '../../utils';
import { ElementId } from './constants';
import model from './model';
import {
  applyFixedReward,
  applyFlexibleReward,
  calculateEarnPointsAmount,
  calculateMaxFlexibleRewardPoints,
  calculateNextFixedRewardDetails,
  calculateNextFlexibleRewardDetails,
  calculateNextRewardDetails,
  createBiLogger,
  defaultState,
  FixedReward,
  FixedRewardSource,
  FLEXIBLE_REWARD_COUPON_NAME,
  FlexibleRewardConfig,
  getAvailableFixedRewards,
  getCheckout,
  getFlexibleRewardConfig,
  getLoyaltyAccount,
  getLoyaltyCoupons,
  getLoyaltyEarningRules,
  getLoyaltyProgram,
  getLoyaltyRewards,
  getReferralRewards,
  getValidFixedRewards,
  ICON_BASE_URL,
  isReferralsAppInstalled,
  LoyaltyCouponFixedReward,
  NextRewardDetails,
  promptLogin,
  ReferralFixedReward,
  ReferralReward,
  RewardDiscountType,
  SelectedRewardType,
  waitForFlexibleRewardRefund,
} from './viewer';

export default model.createController(({ initState, $bind, $bindAll, $widget, $props, flowAPI, $w }) => {
  const { state } = initState(defaultState);
  const biLogger = createBiLogger(flowAPI.bi);
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { isSSR } = flowAPI.environment;
  const { t } = flowAPI.translations;
  const { errorMonitor, experiments } = flowAPI;
  let refreshCheckout: (() => Promise<void>) | undefined;

  const formatNumber = createNumberFormatter(flowAPI);
  const formatCurrency = createCurrencyFormatter(flowAPI);

  const isValidPointsNumber = (value: string) => /^[0-9]+$/.test(value);

  const loadRemoteData = async <T extends unknown[]>(
    requestPromises: T,
    useLoader = true,
  ): Promise<{ [P in keyof T]: Awaited<T[P]> }> => {
    const pendingRequestsCount = requestPromises.length;

    try {
      if (useLoader) {
        state.pendingRequestsCount += pendingRequestsCount;
      }
      return await Promise.all(requestPromises);
    } catch (error) {
      state.hasFailedToLoad = true;
      errorMonitor?.captureException(error as Error);
      console.error(error);
    } finally {
      if (useLoader) {
        state.pendingRequestsCount -= pendingRequestsCount;
      }
    }

    return [] as { [P in keyof T]: Awaited<T[P]> };
  };

  const loadCheckout = async (useLoader = true) => {
    const [checkout] = await loadRemoteData([getCheckout(flowAPI, $props.checkoutId)], useLoader);
    state.checkout = checkout;
  };

  return {
    async pageReady() {
      const mainStates = $w(`#${ElementId.MainStates}`);
      const contentStates = $w(`#${ElementId.ContentStates}`);
      const programIcon = $w(`#${ElementId.ProgramIcon}`);

      const isLoading = computed<boolean>(() => state.pendingRequestsCount > 0);
      const userPointsAmount = computed<number>(() => state.loyaltyAccount?.points?.balance ?? 0);

      const isSubscriptionOrder = computed<boolean>(
        () => state.checkout?.lineItems?.some(({ subscriptionOptionInfo }) => !!subscriptionOptionInfo) ?? false,
      );

      const isGiftCardOrder = computed<boolean>(
        () => state.checkout?.lineItems?.some(({ itemType }) => itemType?.preset === LineItemType.GIFT_CARD) ?? false,
      );

      const activeLoyaltyRewards = computed<LoyaltyReward[]>(
        () => state.loyaltyRewards?.filter(({ active }) => !!active) ?? [],
      );

      const activeLoyaltyCoupons = computed<LoyaltyCoupon[]>(
        () =>
          state.loyaltyCoupons?.filter(
            ({ status, couponReference }) => status === LoyaltyCouponStatus.ACTIVE && !couponReference?.deleted,
          ) ?? [],
      );

      const activeReferralRewards = computed<ReferralReward[]>(
        () => state.referralRewards?.filter(({ coupon }) => coupon?.status === ReferralCouponStatus.ACTIVE) ?? [],
      );

      const activeEarningRules = computed<LoyaltyEarningRule[]>(
        () => state.loyaltyEarningRules?.filter(({ status }) => status === EarningRuleStatus.ACTIVE) ?? [],
      );

      const activeFlexibleReward = computed<LoyaltyReward | undefined>(() =>
        activeLoyaltyRewards
          .get()
          .find(({ type }) => [RewardType.DISCOUNT_AMOUNT, RewardType.SPI_DISCOUNT_AMOUNT].includes(type!)),
      );

      const flexibleRewardConfig = computed<FlexibleRewardConfig>(() =>
        getFlexibleRewardConfig(activeFlexibleReward.get(), state.loyaltyAccount),
      );

      const validFixedRewards = computed<FixedReward[]>(() =>
        state.checkout && state.couponNames
          ? getValidFixedRewards({
              checkout: state.checkout,
              loyaltyAccount: state.loyaltyAccount,
              activeLoyaltyRewards: activeLoyaltyRewards.get(),
              activeLoyaltyCoupons: activeLoyaltyCoupons.get(),
              activeReferralRewards: activeReferralRewards.get(),
              isSubscriptionOrder: isSubscriptionOrder.get(),
              couponNames: state.couponNames,
            })
          : [],
      );

      const availableFixedRewards = computed<FixedReward[]>(() =>
        getAvailableFixedRewards(validFixedRewards.get(), userPointsAmount.get()),
      );

      const customPointsName = computed<string | undefined>(
        () => state.loyaltyProgram?.pointDefinition?.customName ?? undefined,
      );

      // Amount of points the user will earn if he completes this checkout order
      const earnPointsAmount = computed<number>(() => {
        return state.checkout
          ? calculateEarnPointsAmount(activeEarningRules.get(), state.checkout, state.loyaltyAccount)
          : 0;
      });

      const isProgramActive = computed<boolean>(() => state.loyaltyProgram?.status === ProgramStatus.ACTIVE);
      const hasPremiumFeature = computed<boolean>(() => !!state.loyaltyProgram?.premiumFeatures?.loyaltyProgram);
      const canEarnPoints = computed<boolean>(() => !!earnPointsAmount.get());
      const isFlexibleRewardAvailable = computed<boolean>(() => !!flexibleRewardConfig.get().discount);
      const isFixedRewardAvailable = computed<boolean>(() => validFixedRewards.get().length > 0);
      const isRewardTypeSelectionEnabled = computed<boolean>(
        () => isFlexibleRewardAvailable.get() && isFixedRewardAvailable.get(),
      );
      const canRedeemPoints = computed<boolean>(() => isFlexibleRewardAvailable.get() || isFixedRewardAvailable.get());

      // Program is available when user can redeem or earn points
      const isProgramAvailable = computed<boolean>(
        () =>
          !state.hasFailedToLoad &&
          isProgramActive.get() &&
          !isGiftCardOrder.get() &&
          hasPremiumFeature.get() &&
          (canRedeemPoints.get() || canEarnPoints.get()),
      );

      // Details about next available reward (includes amount of points the user is missing etc).
      const nextFlexibleRewardDetails = computed(() =>
        isFlexibleRewardAvailable.get()
          ? calculateNextFlexibleRewardDetails(flexibleRewardConfig.get(), userPointsAmount.get())
          : undefined,
      );
      const nextFixedRewardDetails = computed(() =>
        isFixedRewardAvailable.get() && !availableFixedRewards.get().length
          ? calculateNextFixedRewardDetails(validFixedRewards.get(), userPointsAmount.get())
          : undefined,
      );
      const nextFlexibleOrFixedRewardDetails = computed(() =>
        calculateNextRewardDetails(nextFlexibleRewardDetails.get(), nextFixedRewardDetails.get()),
      );
      const isNextRewardDetailsVisible = computed<boolean>(() => {
        return (
          (state.selectedRewardType === SelectedRewardType.Fixed && !!nextFixedRewardDetails.get()) ||
          (state.selectedRewardType === SelectedRewardType.Flexible && !!nextFlexibleRewardDetails.get())
        );
      });

      const maxFlexibleRewardPointsAmount = computed<number>(() =>
        calculateMaxFlexibleRewardPoints({
          checkout: state.checkout,
          flexibleRewardConfig: flexibleRewardConfig.get(),
          userPointsAmount: userPointsAmount.get(),
        }),
      );

      // Pre-select reward type when only one valid type is available
      when(
        () => !state.selectedRewardType && !isLoading.get(),
        () => {
          if (isRewardTypeSelectionEnabled.get()) {
            if (!nextFlexibleRewardDetails.get() && nextFixedRewardDetails.get()) {
              state.selectedRewardType = SelectedRewardType.Flexible;
            } else if (!nextFixedRewardDetails.get() && nextFlexibleRewardDetails.get()) {
              state.selectedRewardType = SelectedRewardType.Fixed;
            }
          } else if (isFixedRewardAvailable.get()) {
            state.selectedRewardType = SelectedRewardType.Fixed;
          } else {
            state.selectedRewardType = SelectedRewardType.Flexible;
          }
        },
      );

      const selectedFixedReward = computed<FixedReward | undefined>(() =>
        state.selectedFixedRewardId
          ? availableFixedRewards.get().find(({ id }) => id === state.selectedFixedRewardId)
          : undefined,
      );

      const flexibleRewardErrorMessage = computed<string | null>(() => {
        const value = state.flexibleRewardPointsValue.trim();
        const { costInPoints } = flexibleRewardConfig.get();

        if (state.showCouponAlreadyAppliedError) {
          return t('discount-reward.error.other-coupon-applied');
        } else if (state.showFlexibleRewardSubscriptionError) {
          return t('discount-reward.error.subscription-error');
        } else if (state.hasFailedToApplyFlexibleReward) {
          return t('discount-reward.error.unknown-error');
        } else if (value) {
          if (!isValidPointsNumber(value)) {
            return t('discount-reward.error.enter-points-number');
          } else {
            const pointsValue = parseInt(value, 10);
            const minValue = costInPoints;
            const maxValue = maxFlexibleRewardPointsAmount.get();

            if (pointsValue < minValue) {
              return t('discount-reward.error.enter-points-above', { minValue: formatNumber(minValue) });
            } else if (pointsValue > maxValue) {
              return t('discount-reward.error.enter-points-below', { maxValue: formatNumber(maxValue) });
            }
          }
        }

        return null;
      });

      const fixedRewardErrorMessage = computed<string | null>(() => {
        if (state.showCouponAlreadyAppliedError) {
          return t('fixed-reward.error.other-coupon-applied');
        } else if (state.hasFailedToApplyFixedReward) {
          return t('fixed-reward.error.unknown-error');
        } else {
          return null;
        }
      });

      const isValidFlexibleRewardPointsValue = computed<boolean>(
        () =>
          state.hasFailedToApplyFlexibleReward || // Allow to retry on unknown error
          (!flexibleRewardErrorMessage.get() && !!state.flexibleRewardPointsValue.trim()),
      );

      const isValidRewardForm = computed<boolean>(() => {
        if (state.selectedRewardType === SelectedRewardType.Fixed) {
          return !!selectedFixedReward.get();
        } else if (state.selectedRewardType === SelectedRewardType.Flexible) {
          return isValidFlexibleRewardPointsValue.get();
        } else {
          return false;
        }
      });

      const flexibleRewardPoints = computed<number | null>(() => {
        const value = state.flexibleRewardPointsValue.trim();
        return isValidPointsNumber(value) ? parseInt(value, 10) : null;
      });

      const isFlexibleRewardErrorVisible = computed<boolean>(
        () => state.isFlexibleRewardFormTouched && !!flexibleRewardErrorMessage.get(),
      );

      const isOurCouponCodeApplied = computed<boolean>(() => {
        const ourCouponCodes = validFixedRewards
          .get()
          .filter<LoyaltyCouponFixedReward | ReferralFixedReward>(
            (fixedReward): fixedReward is LoyaltyCouponFixedReward | ReferralFixedReward =>
              fixedReward.source === FixedRewardSource.LoyaltyCoupon ||
              fixedReward.source === FixedRewardSource.ReferralReward,
          )
          .map(({ code }) => code);

        return (
          state.checkout?.appliedDiscounts?.some(
            ({ coupon }) => coupon?.name === FLEXIBLE_REWARD_COUPON_NAME || ourCouponCodes.includes(coupon?.code ?? ''),
          ) ?? false
        );
      });

      const isAnyCouponCodeApplied = computed<boolean>(
        () => !!state.checkout?.appliedDiscounts?.some(({ coupon }) => !!coupon),
      );

      const isApplyRewardButtonVisible = computed<boolean>(() => {
        if (!isProgramAvailable.get() || !state.isLoggedIn || isOurCouponCodeApplied.get() || !canRedeemPoints.get()) {
          return false;
        }

        if (isRewardTypeSelectionEnabled.get() && !state.selectedRewardType) {
          return false;
        }

        return !isNextRewardDetailsVisible.get();
      });

      state.isLoggedIn = wixCodeApi.user.currentUser.loggedIn;
      wixCodeApi.user.onLogin((user: IUser) => {
        state.isLoggedIn = user.loggedIn;
      });

      $widget.onPropsChanged((oldProps, newProps) => {
        // HACK: There is no good way to detect external changes to checkout page yet (for example, when coupon is
        // removed or added outside of our widget). As a workaround we are using this undocumented (and probably
        // not intended) behaviour where `$widget.onPropsChanged()` is fired when there are any external changes.
        const shouldReloadCheckout =
          oldProps.checkoutId === newProps.checkoutId &&
          oldProps.stepId === newProps.stepId &&
          oldProps.slotId === newProps.slotId &&
          !!state.checkout &&
          state.isLoggedIn &&
          isProgramAvailable.get() &&
          canRedeemPoints.get();

        if (shouldReloadCheckout) {
          loadCheckout(false);
          state.showCouponAlreadyAppliedError = false;
        }
      });

      // Side effects
      reaction(
        () => state.checkout?.appliedDiscounts,
        async (appliedDiscounts, previousAppliedDiscounts) => {
          const hasOurCoupon = appliedDiscounts?.some(({ coupon }) => coupon?.name === FLEXIBLE_REWARD_COUPON_NAME);
          const hadOurCoupon = previousAppliedDiscounts?.some(
            ({ coupon }) => coupon?.name === FLEXIBLE_REWARD_COUPON_NAME,
          );

          if (!hasOurCoupon && hadOurCoupon) {
            const waitForRemoval = async () => {
              await waitForFlexibleRewardRefund(flowAPI, $props.checkoutId);
              const loyaltyAccount = await getLoyaltyAccount(flowAPI);
              state.loyaltyAccount = loyaltyAccount;
            };
            loadRemoteData([waitForRemoval()]);
          }
        },
      );

      // State changes
      autorun(() => mainStates.changeState(isLoading.get() ? ElementId.LoadingState : ElementId.LoadedState));
      autorun(() => {
        if (isLoading.get()) {
          return;
        }

        if (isOurCouponCodeApplied.get()) {
          contentStates.changeState(ElementId.CodeAppliedState);
        } else if (state.isLoggedIn) {
          if (isRewardTypeSelectionEnabled.get()) {
            contentStates.changeState(ElementId.BothRewardsState);
          } else if (nextFlexibleOrFixedRewardDetails.get()) {
            contentStates.changeState(ElementId.NotEnoughPointsState);
          } else if (isFixedRewardAvailable.get()) {
            contentStates.changeState(ElementId.FixedRewardState);
          } else {
            contentStates.changeState(ElementId.FlexibleRewardState);
          }
        } else {
          contentStates.changeState(ElementId.NotLoggedInState);
        }
      });

      // Load checkout remote data when checkout ID is provided
      when(
        () => !!$props.checkoutId && !isSSR,
        async () => {
          await loadCheckout();

          // We start rendering with one pending request state (to prevent initial content flicker).
          // Also note that $props.checkoutId is not provided in SSR - so in SSR we just render a loader.
          state.pendingRequestsCount--;
        },
      );

      // Load required remote data (for both user logged out and logged in flows)
      (async () => {
        if (isSSR) {
          return;
        }

        const [loyaltyProgram, loyaltyEarningRules, loyaltyRewards, couponNamesInstance] = await loadRemoteData([
          getLoyaltyProgram(flowAPI),
          getLoyaltyEarningRules(flowAPI),
          getLoyaltyRewards(flowAPI),
          loadLoyaltyCouponNames({ i18n: flowAPI.translations.i18n, formatCurrency }),
        ]);

        runInAction(() => {
          state.couponNames = couponNamesInstance;
          state.loyaltyProgram = loyaltyProgram;
          state.loyaltyEarningRules = loyaltyEarningRules;
          state.loyaltyRewards = loyaltyRewards;
        });
      })();

      // Load required remote data when user is logged in
      when(
        () => state.isLoggedIn && !isSSR,
        async () => {
          const shouldLoadReferralRewards = await isReferralsAppInstalled(flowAPI);
          const [loyaltyAccount, loyaltyCoupons, referralRewards] = await loadRemoteData([
            getLoyaltyAccount(flowAPI),
            getLoyaltyCoupons(flowAPI),
            shouldLoadReferralRewards ? getReferralRewards(flowAPI) : [],
          ]);

          runInAction(() => {
            state.loyaltyAccount = loyaltyAccount;
            state.loyaltyCoupons = loyaltyCoupons;
            state.referralRewards = referralRewards;
          });
        },
      );

      when(
        () => !!state.loyaltyProgram?.pointDefinition?.icon?.url,
        () => {
          programIcon.src = `${ICON_BASE_URL}/${state.loyaltyProgram!.pointDefinition!.icon!.url}`;
        },
      );

      $bind(`#${ElementId.ProgramIcon}`, {
        collapsed: () => !state.loyaltyProgram?.pointDefinition?.icon?.url,
      });

      $bind(`#${ElementId.StatusText}`, {
        text() {
          const pointsName = customPointsName.get();

          if (!isProgramAvailable.get()) {
            return t('status.program-not-available');
          } else if (!state.isLoggedIn) {
            const amount = earnPointsAmount.get();

            if (pointsName) {
              return t('status.earn-points.custom', { pointsName, formattedAmount: formatNumber(amount) });
            } else {
              return t('status.earn-points', { amount, formattedAmount: formatNumber(amount) });
            }
          } else {
            const amount = userPointsAmount.get();

            if (pointsName) {
              return t('status.you-have-points.custom', { pointsName, formattedAmount: formatNumber(amount) });
            } else {
              return t('status.you-have-points', { amount, formattedAmount: formatNumber(amount) });
            }
          }
        },
      });

      $bind(`#${ElementId.EarnPointsText}`, {
        text() {
          const amount = earnPointsAmount.get();
          const pointsName = customPointsName.get();

          if (pointsName) {
            return t('earn-points.custom', { pointsName, formattedAmount: formatNumber(amount) });
          } else {
            return t('earn-points', { amount, formattedAmount: formatNumber(amount) });
          }
        },
        collapsed: () => !state.isLoggedIn || !isProgramAvailable.get() || !canEarnPoints.get(),
      });

      $bind(`#${ElementId.ContentStates}`, {
        collapsed: () => !isProgramAvailable.get() || (state.isLoggedIn && !canRedeemPoints.get()),
      } as any);

      $bind(`#${ElementId.LogInText}`, {
        text: () => t('log-in.description'),
      });

      $bind(`#${ElementId.LogInButton}`, {
        label: () => t('log-in.button'),
        async onClick() {
          biLogger.logIn();
          state.isLoggedIn = await promptLogin(flowAPI);

          // NOTE: Checkout page needs a full browser reload after login
          if (state.isLoggedIn) {
            state.pendingRequestsCount++;
            wixCodeApi.location.to?.(wixCodeApi.location.url);
          }
        },
      });

      $bind(`#${ElementId.ChooseRewardText}`, {
        text: () => t('select-reward'),
      });

      $bind(`#${ElementId.FlexibleRewardRadioGroup}`, {
        options: () => {
          const pointsName = customPointsName.get();
          return [
            {
              label: pointsName ? t('select-reward.flexible.custom', { pointsName }) : t('select-reward.flexible'),
              value: SelectedRewardType.Flexible,
            },
          ];
        },
        value: () =>
          state.selectedRewardType === SelectedRewardType.Flexible ? SelectedRewardType.Flexible : (null as any),
        onClick() {
          if (state.selectedRewardType !== SelectedRewardType.Flexible) {
            state.selectedRewardType = SelectedRewardType.Flexible;
            biLogger.selectRewardType(SelectedRewardType.Flexible);
          }
        },
      });

      $bind(`#${ElementId.FixedRewardRadioGroup}`, {
        options: () => [
          {
            label: t('select-reward.fixed'),
            value: SelectedRewardType.Fixed,
          },
        ],
        value: () => (state.selectedRewardType === SelectedRewardType.Fixed ? SelectedRewardType.Fixed : (null as any)),
        onClick() {
          if (state.selectedRewardType !== SelectedRewardType.Fixed) {
            state.selectedRewardType = SelectedRewardType.Fixed;
            biLogger.selectRewardType(SelectedRewardType.Fixed);
          }
        },
      });

      $bind(`#${ElementId.RadioGroupSpacer}`, {
        collapsed: () => state.selectedRewardType !== SelectedRewardType.Flexible,
      });

      const notEnoughPointsWidgetHandlers = (nextRewardDetails: IComputedValue<NextRewardDetails | undefined>) => ({
        label() {
          const nextReward = nextRewardDetails.get();
          if (!nextReward) {
            return '';
          }

          const points = nextReward.missingUserPoints;
          const pointsName = customPointsName.get();
          let amount = '';

          if (nextReward.discountType === RewardDiscountType.Money) {
            amount = formatCurrency(nextReward.moneyAmountDiscount);
          } else if (nextReward.discountType === RewardDiscountType.Percentage) {
            amount = `${formatNumber(nextReward.percentageDiscount)}%`;
          }

          if (pointsName) {
            const translationKey =
              nextReward.discountType === RewardDiscountType.FreeShipping
                ? 'points-needed.free-shipping.custom'
                : 'points-needed.discount.custom';

            return t(translationKey, { points, amount, pointsName, formattedPoints: formatNumber(points) });
          } else {
            const translationKey =
              nextReward.discountType === RewardDiscountType.FreeShipping
                ? 'points-needed.free-shipping'
                : 'points-needed.discount';

            return t(translationKey, { points, amount, formattedPoints: formatNumber(points) });
          }
        },
        progressMaxValue: () => nextRewardDetails.get()?.costInPoints || 100,
        progressValue: () => userPointsAmount.get(),
        progressStatus() {
          const userPoints = formatNumber(userPointsAmount.get());
          const nextRewardPoints = formatNumber(nextRewardDetails.get()?.costInPoints ?? 0);

          return `${userPoints}/${nextRewardPoints}`;
        },
      });

      $bindAll({
        [`#${ElementId.NotEnoughPointsWidget}`]: notEnoughPointsWidgetHandlers(nextFlexibleOrFixedRewardDetails),
        [`#${ElementId.NotEnoughPointsWidgetFlexible}`]: {
          ...notEnoughPointsWidgetHandlers(nextFlexibleRewardDetails),
          collapsed: () => state.selectedRewardType !== SelectedRewardType.Flexible || !nextFlexibleRewardDetails.get(),
        },
        [`#${ElementId.NotEnoughPointsWidgetFixed}`]: {
          ...notEnoughPointsWidgetHandlers(nextFixedRewardDetails),
          collapsed: () => state.selectedRewardType !== SelectedRewardType.Fixed || !nextFixedRewardDetails.get(),
        },
      });

      const flexibleRewardWidgetHandlers = {
        inputLabel() {
          if (isRewardTypeSelectionEnabled.get()) {
            return '';
          }

          const pointsName = customPointsName.get();
          return pointsName
            ? t('discount-reward.points-input.label.custom', { pointsName })
            : t('discount-reward.points-input.label');
        },
        inputPlaceholder: () =>
          t('discount-reward.points-input.placeholder', { points: formatNumber(maxFlexibleRewardPointsAmount.get()) }),
        inputValue: () => state.flexibleRewardPointsValue,
        onInput(event: any) {
          runInAction(() => {
            state.isFlexibleRewardFormTouched = true;
            state.hasFailedToApplyFlexibleReward = false;
            state.showCouponAlreadyAppliedError = false;
            state.showFlexibleRewardSubscriptionError = false;
            state.flexibleRewardPointsValue = event.data;
          });
        },
        onInputBlur() {
          state.isFlexibleRewardFormTouched = true;
        },
        onInputChange() {
          state.isFlexibleRewardFormTouched = true;
        },
        hasFailedToApplyReward: () => state.hasFailedToApplyFlexibleReward,
        errorMessage: () => {
          if (!isFlexibleRewardErrorVisible.get()) {
            return '';
          }
          return flexibleRewardErrorMessage.get() ?? '';
        },
        statusText: () => {
          const pointsName = customPointsName.get();
          const { name, costInPoints, discountPerPoint } = flexibleRewardConfig.get();
          const isValidEnteredPointsAmount = isValidFlexibleRewardPointsValue.get();

          const enteredPointsAmount = flexibleRewardPoints.get();
          const pointsAmount = isValidEnteredPointsAmount ? enteredPointsAmount! : costInPoints;
          const calculatedDiscount = Math.round(pointsAmount * discountPerPoint * 100) / 100;
          const discountAmount = formatCurrency(calculatedDiscount);

          const rewardDetailsText = pointsName
            ? t('discount-reward.status.custom', {
                formattedPoints: formatNumber(pointsAmount),
                discountAmount,
                pointsName,
              })
            : t('discount-reward.status', {
                pointsAmount,
                discountAmount,
                formattedPoints: formatNumber(pointsAmount),
              });

          if (experiments.enabled(Experiment.FetchCustomRewardsFromSpi) && !!name) {
            return `${name}: ${rewardDetailsText}`;
          }

          return rewardDetailsText;
        },
      };

      $bindAll({
        [`#${ElementId.FlexibleRewardWidget}`]: flexibleRewardWidgetHandlers,
        [`#${ElementId.FlexibleRewardWidgetBoth}`]: {
          ...flexibleRewardWidgetHandlers,
          collapsed: () =>
            state.selectedRewardType !== SelectedRewardType.Flexible || !!nextFlexibleRewardDetails.get(),
        },
      });

      // Pre-select first available fixed reward option
      when(
        () => !isLoading.get() && !state.selectedFixedRewardId && !!availableFixedRewards.get().length,
        () => {
          state.selectedFixedRewardId = availableFixedRewards.get()[0].id;
        },
      );

      const fixedRewardWidgetHandlers = {
        dropdownLabel: () => (isRewardTypeSelectionEnabled.get() ? '' : t('fixed-reward.dropdown.label')),
        dropdownOptions: () => {
          const pointsName = customPointsName.get();

          return availableFixedRewards.get().map((fixedReward) => {
            let label = fixedReward.name;

            if (fixedReward.source === FixedRewardSource.LoyaltyReward) {
              const { name, costInPoints } = fixedReward;
              const formattedPoints = formatNumber(costInPoints);

              label = pointsName
                ? t('fixed-reward.dropdown.item-name.custom', {
                    rewardName: name,
                    formattedPoints,
                    pointsName,
                  })
                : t('fixed-reward.dropdown.item-name', {
                    rewardName: name,
                    points: costInPoints,
                    formattedPoints,
                  });
            }

            return {
              label,
              value: fixedReward.id,
            };
          }) as any;
        },
        dropdownValue: () => state.selectedFixedRewardId ?? '',
        onDropdownChange(event: any) {
          runInAction(() => {
            state.hasFailedToApplyFixedReward = false;
            state.selectedFixedRewardId = event.data;
          });

          const selectedRewardName = selectedFixedReward.get()?.name;
          if (selectedRewardName) {
            biLogger.selectFixedReward(selectedRewardName);
          }
        },
        errorMessage: () => fixedRewardErrorMessage.get() ?? '',
        statusText: () => {
          const selectedReward = selectedFixedReward.get();
          if (!selectedReward) {
            return '';
          }

          const discountDescription = selectedReward.description;
          if (selectedReward.source !== FixedRewardSource.LoyaltyReward) {
            return discountDescription;
          }

          const { costInPoints } = selectedReward;
          const formattedPoints = formatNumber(costInPoints);
          const pointsName = customPointsName.get();

          if (pointsName) {
            return t('fixed-reward.status.custom', {
              formattedPoints,
              pointsName,
              discountDescription,
            });
          } else {
            return t('fixed-reward.status', {
              points: costInPoints,
              formattedPoints,
              discountDescription,
            });
          }
        },
      };

      $bindAll({
        [`#${ElementId.FixedRewardWidget}`]: fixedRewardWidgetHandlers,
        [`#${ElementId.FixedRewardWidgetBoth}`]: {
          ...fixedRewardWidgetHandlers,
          collapsed: () => state.selectedRewardType !== SelectedRewardType.Fixed || !!nextFixedRewardDetails.get(),
        },
      });

      const handleApplyFlexibleRewardClick = async () => {
        state.hasFailedToApplyFlexibleReward = false;

        if (isSubscriptionOrder.get()) {
          state.showFlexibleRewardSubscriptionError = true;
        } else if (isValidFlexibleRewardPointsValue.get()) {
          try {
            state.pendingRequestsCount++;

            const enteredPointsAmount = flexibleRewardPoints.get()!;
            biLogger.applyFlexibleReward(enteredPointsAmount);

            await applyFlexibleReward({
              flowAPI,
              pointsToSpend: enteredPointsAmount,
              flexibleReward: activeFlexibleReward.get()!,
              checkoutId: $props.checkoutId,
            });

            await Promise.all([refreshCheckout?.(), loadCheckout()]);

            runInAction(() => {
              state.flexibleRewardPointsValue = '';
              if (state.loyaltyAccount?.points?.balance) {
                state.loyaltyAccount.points.balance -= enteredPointsAmount;
              }
            });
          } catch (error) {
            state.hasFailedToApplyFlexibleReward = true;
            errorMonitor?.captureException(error as Error);
            console.error(error);
          } finally {
            state.pendingRequestsCount--;
          }
        }
      };

      const handleApplyFixedRewardClick = async () => {
        state.hasFailedToApplyFixedReward = false;

        try {
          state.pendingRequestsCount++;
          const fixedReward = selectedFixedReward.get()!;

          biLogger.applyFixedReward({
            fixedReward,
            availableFixedRewards: availableFixedRewards.get(),
            userPointsAmount: userPointsAmount.get(),
          });

          await applyFixedReward({
            flowAPI,
            fixedReward,
            checkoutId: $props.checkoutId,
          });

          const [[loyaltyCoupons]] = await Promise.all([
            // Reload loyalty coupons after redeeming loyalty reward (in case user will decide to
            // manually remove coupon in checkout page)
            fixedReward.source === FixedRewardSource.LoyaltyReward
              ? loadRemoteData([getLoyaltyCoupons(flowAPI)])
              : [undefined],
            refreshCheckout?.(),
            loadCheckout(),
          ]);

          runInAction(() => {
            if (loyaltyCoupons) {
              state.loyaltyCoupons = loyaltyCoupons;
            }

            if (state.loyaltyAccount?.points?.balance && fixedReward.source === FixedRewardSource.LoyaltyReward) {
              state.loyaltyAccount.points.balance -= fixedReward.costInPoints;
            }

            state.selectedFixedRewardId = availableFixedRewards.get()[0].id;
          });
        } catch (error) {
          state.hasFailedToApplyFixedReward = true;
          errorMonitor?.captureException(error as Error);
          console.error(error);
        } finally {
          state.pendingRequestsCount--;
        }
      };

      $bind(`#${ElementId.ApplyRewardButton}`, {
        collapsed: () => !isApplyRewardButtonVisible.get(),
        label: () => t('discount-reward.redeem-reward'),
        disabled: () => !isValidRewardForm.get(),
        async onClick() {
          if (isAnyCouponCodeApplied.get()) {
            state.showCouponAlreadyAppliedError = true;
          } else {
            if (state.selectedRewardType === SelectedRewardType.Fixed) {
              await handleApplyFixedRewardClick();
            } else if (state.selectedRewardType === SelectedRewardType.Flexible) {
              await handleApplyFlexibleRewardClick();
            }
          }
        },
      });

      $bind(`#${ElementId.CodeAppliedText}`, {
        text: () => t('code-applied'),
      });
    },
    exports: {
      onRefreshCheckout(refreshCheckoutCallback: () => Promise<void>) {
        refreshCheckout = refreshCheckoutCallback;
      },
    },
  };
});
